import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vant/lib/index.css'; // 引入Vant的样式

import vant from 'vant';
Vue.use(vant);

// 自定义过滤器
import filter from '@/filter';
Vue.use(filter);

// vxe-table
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable);

Vue.prototype.window = window;

// 深拷贝
import { deepClone } from '@/utils/tools';
Vue.prototype.$deepClone = deepClone;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
