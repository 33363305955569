import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/index/Index.vue'),
  },

  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/login/Index.vue'),
  },

  {
    path: '/index',
    name: 'index',
    meta: {
      title: '首页',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/index/Index.vue'),
  },

  {
    path: '/order',
    name: 'order',
    meta: {
      title: '订单列表',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/order/Index.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      title: '我的',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/profile/Index.vue'),
  },
  {
    path: '/order/detail',
    name: 'detail',
    meta: {
      title: '我的',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/order/detail/Index.vue'),
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      title: '产品',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/product/Index.vue'),
  },
  {
    path: '/product/detail',
    name: 'productDetail',
    meta: {
      title: '产品',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/product/detail/Index.vue'),
  },
  {
    path: '/bankList',
    name: 'bankList',
    meta: {
      title: '银行卡',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/bank_list/Index.vue'),
  },
  {
    path: '/bindCard',
    name: 'bindCard',
    meta: {
      title: '绑卡管理',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/bank_list/Bindcard.vue'),
  },
  {
    path: '/governor',
    name: 'governorBroker',
    meta: {
      title: '绑卡管理',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/governor_broker_manage/Index.vue'),
  },
  {
    path: '/invite',
    name: 'invite',
    meta: {
      title: '邀请记录',
    },
    component: () => import(/* webpackChunkName: "router" */ '@/views/invite/Index.vue'),
  },



];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.meta && to.meta.title && (document.title = to.meta.title);

  if ( to.path!=='/login') {

    const u = JSON.parse(localStorage.userInfo || '{}');
    // 有用户信息 且没过期 就直接前往
    if (u.token) { // && moment().isBefore(u.expiresTime)
      return next()
    }else {
     // router.replace(`/login`);

      return next()
    }
  }
  next()
});

export default router;
